// profile icon
if($('.profile-user').length > 0){
    $('.profile-user').initial({
        width: 46,
        height: 46,
        fontSize: 20,
        fontWeight: 700
    });
}


// $(".show-password").on("click", function(){
//     var $input = $(this).siblings('input');
//     var $icon  = $(this).find('.icon');
//     $icon.toggleClass('bi-eye bi-eye-slash');
//     ($input.attr('type') === 'password') ? $input.attr('type', 'text') : $input.attr('type', 'password');
// });
//   
if($("#menu").length > 0){
    $("#menu").metisMenu();
}
if($(".deznav-scroll").length > 0){
    new PerfectScrollbar(".deznav-scroll");
}
$(".nav-control").on("click", function () {
    $("#main-wrapper").toggleClass("menu-toggle"), $(".hamburger").toggleClass("is-active")
})

// datepicker

if($('.datepicker').length > 0){
    $('.datepicker').datepicker({
        dateFormat: "dd/mm/yy",
        changeMonth: true,
        changeYear: true,
    });
}

// if($('#sortable').length > 0){
//     var table = $('#sortable').DataTable({
//         "dom" : 'B <"table-responsive" t> <"table-footer" <"row" <"col-auto align-self-center ml-auto" <"justify-content-end" <"text-md-right" <"dropdown-select" l <i>> > > > <"col-md-auto" p> >  >',
//         "columnDefs": [
//             { "targets": 'no-sort', "orderable": false }
//         ],
//         "language": {
//             "lengthMenu": "<span class='mb-0'>Rows per page:</span>_MENU_",
//             "info": "_START_ - _END_ of _TOTAL_",
//             "infoEmpty":"0 - 0 of 0",
//             "paginate": {
//                 "next": '<i class="bi bi-chevron-right"></i>',
//                 "previous": '<i class="bi bi-chevron-left"></i>',
//             }
//         },
//         buttons: [ 'excel', 'pdf', 'copy', 'print'],
//         "initComplete": function(settings, json) {
//             $(".apploader").removeClass('show');
//             $(".card-table").removeClass('loading')

//         }
//     });
//     $('#table_search').on('keyup change', function () {
//         table.search(this.value).draw();
//     });
//     if( $(".btn-excel").length > 0){
//         $(".btn-excel").on("click", function(){
//             table.button( '.buttons-excel' ).trigger();
//         })
//     }
//     if( $(".btn-pdf").length > 0){
//         $(".btn-pdf").on("click", function(){
//             table.button( '.buttons-pdf' ).trigger();
//         })
//     }

//     if( $(".btn-copy").length > 0){
//         $(".btn-copy").on("click", function(){
//             table.button( '.buttons-copy' ).trigger();
//         })
//     }

//     if( $(".btn-print").length > 0){
//         $(".btn-print").on("click", function(){
//             table.button( '.buttons-print' ).trigger();
//         })
//     }
    
   
// }

// autocomplete 
if($(".autoComplete").length > 0){
$( function() {
    var principalList = [
      "Dr. Rachel Turner",
      "Angie Courtney",
      "Chris Hodge"
    ];
    $("#principal_name").autocomplete({
      source: principalList
    });
    var secretarylList = [
        "Kati Lager",
        "Megan Miller",
        "Karrie Richerson",
      ];
      $("#secretary_name").autocomplete({
        source: secretarylList
      });
  });
}


$(function() {
    $('.custom-file-input').change(function(){
      var t = $(this).val();
      var labelText = t.substr(12, t.length);
      $(this).next('label').text(labelText);
    })
  });

if($(".user_role").length > 0){
    $('.ui.checkbox.chEnable').on("click", function(){
        var inputChck = $(this).find('input');
        if(!inputChck.is(':checked')){
            $(this).parents('tr').find('td').each(function (index) {
                if (!index == 0) {
                    $(this).addClass('disabled');
                    $(this).find('.ui.checkbox').addClass('disabled');
                }
            });   
        } else{
            $(this).parents('tr').find('td').each(function (index) {
                if (!index == 0) {
                    $(this).removeClass('disabled');
                    $(this).find('.ui.checkbox').removeClass('disabled');
                }
            });    
        }
    })
    $('.roleMenu a.item').on("click", function (e) {
        if(e.target !== e.currentTarget) return;
        if (!$(this).hasClass('active')) {
            $('.card-table').addClass('loading');
            $('.apploader').addClass('show');
            $('.roleMenu a.item').removeClass('active');
            $(this).addClass('active');
            setTimeout(function () {
                $('.card-table').removeClass('loading');
                $('.apploader').removeClass('show');
            }, 3000)
        }
    });
   
}

// add/edit role
let addEditRole = (roleName, roleID) => {
    var roleId = roleID;
    let modalId = $('.addEditRole');
    if (roleName == "" && roleID == "") {
        let numRole = $(".roleMenu li:last-child .item").attr('id')
            lastId = '',
            LastCount = '' ;         
        if(numRole){
            LastCount = numRole.split('role');
            lastId = 'role'+parseInt(parseInt(LastCount[1]) + 1)
        } else{
            LastCount = "1"
            lastId = 'role1';
        }
        modalId.find('.modal-header').text("Add New Role");
        modalId.find('#roleId').val('');
        modalId.find('#roleName').val('');
        modalId.modal('show');
        
        
    } else {      
        modalId.find('.modal-header').text("Edit Role");
        modalId.find('#roleId').val(roleID);
        modalId.find('#roleName').val(roleName);
        modalId.modal('show');
       
    }
};

let delRole = (roleID) =>{
    let deleteList = $(".roleMenu li #role"+roleID+"").parent();
    $("#deletePopup").modal('show');
}

// sticky page header
$(window).on("scroll", function(){    
    if($(window).scrollTop() > ($(".header").height())){
      $(".sub-header").slideUp('fast');   
    } else{
      $(".sub-header").slideDown('fast');
    }
  });